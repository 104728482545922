import { APICore } from './apiCore';

const api = new APICore();


function getStyle(params: { limit: number, page: number }) {
    const baseUrl = '/api/styles/';
    return api.get(`${baseUrl}`, params);
}

function getAllStyle(params: { type: any }) {
    const baseUrl = '/api/styles/';
    const new_params = params?.type !== undefined ? { 'type': params?.type } : {}
    return api.get(`${baseUrl}`, new_params);
}

function getAllActiveOrInactiveStyle(params: { is_active: string, type: any }) {
    const baseUrl = '/api/styles/';
    const new_params = params?.is_active !== undefined ? { 'is_active': params?.is_active } : {}

    if (params?.type !== '' && params?.type !== undefined && params?.type !== null) {
        return api.get(`${baseUrl}`, { ...new_params, type: params?.type });
    }
    
    return api.get(`${baseUrl}`, new_params);
}

function addStyle(params: { style_no: string, buyer: string, linking_rate: number, trimming_rate: number, mending_rate: number, type: string }) {
    const baseUrl = '/api/styles/';
    return api.create(`${baseUrl}`, params);
}

function updateStyle(params: { id: any, style_no: string, buyer: string, rate: number, type: string }) {
    const baseUrl = `/api/styles/${params?.id}/`;
    return api.updatePatch(`${baseUrl}`, params);
}


export { getStyle, addStyle, getAllStyle, getAllActiveOrInactiveStyle, updateStyle };
