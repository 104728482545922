import * as type from './types';

const INIT_STATE = {
    styles: [],
    all_styles: [],
    previous: '',
    next: '',
    current_page: '',
    total_object: '',
    total_page: '',
    active: '',
    loading: false,
    error: null,
    success: '',
};



const Styles = (state = INIT_STATE, action: any) => {
    switch (action.type) {

        case type.GET_STYLE_REQUESTED: {
            return {
                ...state,
                loading: true,
            };
        }
        case type.GET_STYLE_SUCCESS: {
            return {
                ...state,
                loading: false,
                styles: action.data.results,
                previous: action.data.previous,
                next: action.data.next,
                current_page: action.data.current_page,
                total_object: action.data.total_object,
                total_page: action.data.total_page,
                active: action.data.current_page,

            };
        }
        case type.GET_STYLE_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }

        case type.GET_ALLSTYLE_REQUESTED: {
            return {
                ...state,
                loading: true,
            };
        }
        case type.GET_ALLSTYLE_SUCCESS: {
            return {
                ...state,
                loading: false,
                all_styles: action.data,
                previous: action.data.previous,
                next: action.data.next,
                current_page: action.data.current_page,
                total_page: action.data.total_page,
                active: action.data.current_page,
            };
        }
        case type.GET_ALLSTYLE_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }

        case type.ADD_STYLE_REQUESTED: {
            return {
                ...state,
                loading: true,
            };
        }
        case type.ADD_STYLE_SUCCESS: {
            return {
                ...state,
                loading: false,
                styles: [action.style, ...state.all_styles],
                success: 'Style Created Successfully'

            };
        }
        case type.ADD_STYLE_FAILED: {
            return {
                ...state,
                loading: false,
                error: (action?.error?.style_no !== null || action?.error?.style_no !== undefined) ? (action?.error?.style_no[0]).toString() : (action?.error).toString(),
            };
        }


        case type.UPDATE_STYLE_REQUESTED: {
            return {
                ...state,
                loading: true,
            };
        }

        case type.UPDATE_STYLE_SUCCESS: {
            return {
                ...state,
                loading: false,
                styles_details: action.data,
                success: 'Style Updated Successfully'

            };
        }


        case type.UPDATE_STYLE_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }


        case type.GET_ACTIVE_OR_INACTIVE_STYLE_REQUESTED: {
            return {
                ...state,
                loading: true,
            };
        }
        case type.GET_ACTIVE_OR_INACTIVE_STYLE_SUCCESS: {
            const all_styles_for_select = action?.data?.map((style: any) => {
                const newStyle = { ...style };
                newStyle['value'] = newStyle.id;
                newStyle['label'] = newStyle.style_no;

                return newStyle;
            })


            return {
                ...state,
                loading: false,
                all_styles: action.data,
                all_styles_for_select: all_styles_for_select,

            };
        }
        case type.GET_ACTIVE_OR_INACTIVE_STYLE_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }


        case type.SET_STYLE_SUCCESS_ALERT: {

            return {
                ...state,
                success: action.payload,
            };
        }

        case type.SET_STYLE_ERROR_ALERT: {

            return {
                ...state,
                error: action.payload,
            };
        }

        default:
            return state;
    }
};

export default Styles;
