export const GET_OPERATOR_REQUESTED ='GET_OPERATOR_REQUESTED'
export const GET_OPERATOR_SUCCESS ='GET_OPERATOR_SUCCESS'
export const GET_OPERATOR_FAILED ='GET_OPERATOR_FAILED'

export const GET_ALLOPERATOR_REQUESTED ='GET_ALLOPERATOR_REQUESTED'
export const GET_ALLOPERATOR_SUCCESS ='GET_ALLOPERATOR_SUCCESS'
export const GET_ALLOPERATOR_FAILED ='GET_ALLOPERATOR_FAILED'

export const ADD_OPERATOR_REQUESTED ='ADD_OPERATOR_REQUESTED'
export const ADD_OPERATOR_SUCCESS ='ADD_OPERATOR_SUCCESS'
export const ADD_OPERATOR_FAILED ='ADD_OPERATOR_FAILED'

export const UPDATE_OPERATOR_REQUESTED ='UPDATE_OPERATOR_REQUESTED'
export const UPDATE_OPERATOR_SUCCESS ='UPDATE_OPERATOR_SUCCESS'
export const UPDATE_OPERATOR_FAILED ='UPDATE_OPERATOR_FAILED'

export const GET_ACTIVE_OR_INACTIVE_OPERATOR_REQUESTED ='GET_ACTIVE_OR_INACTIVE_OPERATOR_REQUESTED'
export const GET_ACTIVE_OR_INACTIVE_OPERATOR_SUCCESS ='GET_ACTIVE_OR_INACTIVE_OPERATOR_SUCCESS'
export const GET_ACTIVE_OR_INACTIVE_OPERATOR_FAILED ='GET_ACTIVE_OR_INACTIVE_OPERATOR_FAILED'


export const SET_OPERATOR_SUCCESS_ALERT ='SET_OPERATOR_SUCCESS_ALERT'
export const SET_OPERATOR_ERROR_ALERT ='SET_OPERATOR_ERROR_ALERT'

export const OPERATOR_DETAILS_CLEAR ='OPERATOR_DETAILS_CLEAR'