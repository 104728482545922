export const GET_PRODUCTION_ENTRY_REQUESTED ='GET_PRODUCTION_ENTRY_REQUESTED'
export const GET_PRODUCTION_ENTRY_SUCCESS ='GET_PRODUCTION_ENTRY_SUCCESS'
export const GET_PRODUCTION_ENTRY_FAILED ='GET_PRODUCTION_ENTRY_FAILED'

export const GET_ALL_PRODUCTION_ENTRY_REQUESTED ='GET_ALL_PRODUCTION_ENTRY_REQUESTED'
export const GET_ALL_PRODUCTION_ENTRY_SUCCESS ='GET_ALL_PRODUCTION_ENTRY_SUCCESS'
export const GET_ALL_PRODUCTION_ENTRY_FAILED ='GET_ALL_PRODUCTION_ENTRY_FAILED'

export const ADD_PRODUCTION_ENTRY_REQUESTED ='ADD_PRODUCTION_ENTRY_REQUESTED'
export const ADD_PRODUCTION_ENTRY_SUCCESS ='ADD_PRODUCTION_ENTRY_SUCCESS'
export const ADD_PRODUCTION_ENTRY_FAILED ='ADD_PRODUCTION_ENTRY_FAILED'

export const UPDATE_PRODUCTION_ENTRY_REQUESTED ='UPDATE_PRODUCTION_ENTRY_REQUESTED'
export const UPDATE_PRODUCTION_ENTRY_SUCCESS ='UPDATE_PRODUCTION_ENTRY_SUCCESS'
export const UPDATE_PRODUCTION_ENTRY_FAILED ='UPDATE_PRODUCTION_ENTRY_FAILED'


export const SET_PRODUCTION_ENTRY_SUCCESS_ALERT ='SET_PRODUCTION_ENTRY_SUCCESS_ALERT'
export const SET_PRODUCTION_ENTRY_ERROR_ALERT ='SET_PRODUCTION_ENTRY_ERROR_ALERT'
