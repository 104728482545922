import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

// components
import PrivateRoute from './PrivateRoute';
import Root from './Root';

// lazy load all the views

// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const Register = React.lazy(() => import('../pages/auth/Register'));
const SignInSignUp = React.lazy(() => import('../pages/auth/SignInSignUp'));
const LockScreen = React.lazy(() => import('../pages/auth/LockScreen'));


// - crm pages
const Dashboard = React.lazy(() => import('../pages/apps/Dashboard/'));
const Operators = React.lazy(() => import('../pages/apps/Operators/'));
const Styles = React.lazy(() => import('../pages/apps/Styles/'));
const ProductionEntry = React.lazy(() => import('../pages/apps/ProductionEntry/'));
const ProductionReport = React.lazy(() => import('../pages/apps/ProductionReport/'));
const SalaryReport = React.lazy(() => import('../pages/apps/Salary/'));

// extra pages
const Error404 = React.lazy(() => import('../pages/error/Error404'));
const Error404Two = React.lazy(() => import('../pages/error/Error404Two'));
const Error404Alt = React.lazy(() => import('../pages/error/Error404Alt'));
const Error500 = React.lazy(() => import('../pages/error/Error500'));
const Error500Two = React.lazy(() => import('../pages/error/Error500Two'));

export interface RoutesProps {
    path: RouteProps['path'];
    name?: string;
    component?: RouteProps['component'];
    route?: any;
    exact?: RouteProps['exact'];
    icon?: string;
    header?: string;
    roles?: string[];
    children?: RoutesProps[];
}

// root routes
const rootRoute: RoutesProps = {
    path: '/',
    exact: true,
    component: () => <Root />,
    route: Route,
};


const AppRoutes: RoutesProps[] = [
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        route: PrivateRoute,
    },
    {
        path: '/production-report',
        name: 'Production Report',
        component: ProductionReport,
        route: PrivateRoute,
    },
    {
        path: '/Trimming',
        name: 'trimming',
        component: ProductionEntry,
        route: PrivateRoute,
    },
    {
        path: '/mending',
        name: 'Mending',
        component: ProductionEntry,
        route: PrivateRoute,
    },
    {
        path: '/operators',
        name: 'Operators',
        component: Operators,
        route: PrivateRoute,
    },
    {
        path: '/styles',
        name: 'Styles',
        component: Styles,
        route: PrivateRoute,
    },
];

// linking
const linkingRoutes: RoutesProps = {
    path: '/linking',
    name: 'Linking',
    icon: 'airplay',
    header: 'Navigation',
    children: [
        {
            path: '/linking/7gg',
            name: 'Linking 7GG',
            component: ProductionEntry,
            route: PrivateRoute,
        },
        {
            path: '/linking/12gg',
            name: 'Linking 12GG',
            component: ProductionEntry,
            route: PrivateRoute,
        },
    ],
};

// salary
const salaryRoutes: RoutesProps = {
    path: '/salary',
    name: 'Salary',
    icon: 'airplay',
    header: 'Navigation',
    children: [
        {
            path: '/salary/linking-7gg',
            name: 'Salary Linking 7GG',
            component: SalaryReport,
            route: PrivateRoute,
        },
        {
            path: '/salary/linking-12gg',
            name: 'Salary Linking 12GG',
            component: SalaryReport,
            route: PrivateRoute,
        },
        {
            path: '/salary/trimming',
            name: 'Salary Trimming',
            component: SalaryReport,
            route: PrivateRoute,
        },
        {
            path: '/salary/mending',
            name: 'Salary Mending',
            component: SalaryReport,
            route: PrivateRoute,
        },
    ],
};

const appRoutes = [
    linkingRoutes,
    salaryRoutes,
];

// pages
const extrapagesRoutes = {
    path: '/pages',
    name: 'Pages',
    icon: 'package',
    header: 'Custom',
    children: [
        {
            path: '/pages/error-404-alt',
            name: 'Error - 404-alt',
            component: Error404Alt,
            route: PrivateRoute,
        },
    ],
};

// auth
const authRoutes: RoutesProps[] = [
    {
        path: '/auth/login',
        name: 'Login',
        component: Login,
        route: Route,
    },
    {
        path: '/auth/register',
        name: 'Register',
        component: Register,
        route: Route,
    },
    {
        path: '/auth/confirm',
        name: 'Confirm',
        component: Confirm,
        route: Route,
    },
    {
        path: '/auth/forget-password',
        name: 'Forget Password',
        component: ForgetPassword,
        route: Route,
    },
    {
        path: '/auth/signin-signup',
        name: 'SignIn-SignUp',
        component: SignInSignUp,
        route: Route,
    },
    {
        path: '/auth/lock-screen',
        name: 'Lock Screen',
        component: LockScreen,
        route: Route,
    },
    {
        path: '/auth/logout',
        name: 'Logout',
        component: Logout,
        route: Route,
    }
];

// public routes
const otherPublicRoutes = [
    {
        path: '/error-404',
        name: 'Error - 404',
        component: Error404,
        route: Route,
    },
    {
        path: '/error-404-two',
        name: 'Error - 404 Two',
        component: Error404Two,
        route: Route,
    },
    {
        path: '/error-500',
        name: 'Error - 500',
        component: Error500,
        route: Route,
    },
    {
        path: '/error-500-two',
        name: 'Error - 500 Two',
        component: Error500Two,
        route: Route,
    },
];

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
    let flatRoutes: RoutesProps[] = [];

    routes = routes || [];
    routes.forEach((item: RoutesProps) => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes

const authProtectedRoutes = [rootRoute, ...appRoutes, extrapagesRoutes];
const publicRoutes = [...authRoutes, ...otherPublicRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes, ...AppRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
export { publicRoutes, authProtectedRoutes, authProtectedFlattenRoutes, publicProtectedFlattenRoutes };
