export enum AuthActionTypes {
    API_RESPONSE_SUCCESS = '@@auth/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@auth/API_RESPONSE_ERROR',

    LOGIN_USER = '@@auth/LOGIN_USER',
    LOGOUT_USER = '@@auth/LOGOUT_USER',
    SIGNUP_USER = '@@auth/SIGNUP_USER',


    RESET = '@@auth/RESET',
    UPDATE_PROFILE = '@@auth/UPDATE_PROFILE',
    UPDATE_PROFILE_IMAGE = '@@auth/UPDATE_PROFILE_IMAGE',
    SET_AUTH_SUCCESS_ALERT = '@@auth/SET_AUTH_SUCCESS_ALERT',
    SET_AUTH_ERROR_ALERT = '@@auth/SET_AUTH_ERROR_ALERT',
}
