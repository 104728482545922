import * as type from './types';

const INIT_STATE = {
    production_entries: [],
    all_production_entries: [],
    previous: '',
    next: '',
    current_page: '',
    total_object: '',
    total_page: '',
    active: '',
    loading: false,
    error: null,
    success: '',
};



const ProductionEntry = (state = INIT_STATE, action: any) => {
    switch (action.type) {

        case type.GET_PRODUCTION_ENTRY_REQUESTED: {
            return {
                ...state,
                loading: true,
            };
        }
        case type.GET_PRODUCTION_ENTRY_SUCCESS: {
            return {
                ...state,
                loading: false,
                production_entries: action.data.results,
                previous: action.data.previous,
                next: action.data.next,
                current_page: action.data.current_page,
                total_object: action.data.total_object,
                total_page: action.data.total_page,
                active: action.data.current_page,

            };
        }
        case type.GET_PRODUCTION_ENTRY_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }

        case type.GET_ALL_PRODUCTION_ENTRY_REQUESTED: {
            return {
                ...state,
                loading: true,
            };
        }
        case type.GET_ALL_PRODUCTION_ENTRY_SUCCESS: {
            return {
                ...state,
                loading: false,
                all_production_entries: action.data,
                previous: action.data.previous,
                next: action.data.next,
                current_page: action.data.current_page,
                total_page: action.data.total_page,
                active: action.data.current_page,
            };
        }
        case type.GET_ALL_PRODUCTION_ENTRY_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }

        case type.ADD_PRODUCTION_ENTRY_REQUESTED: {
            return {
                ...state,
                loading: true,
            };
        }
        case type.ADD_PRODUCTION_ENTRY_SUCCESS: {
            return {
                ...state,
                loading: false,
                all_production_entries: [action.production_entry, ...state.all_production_entries],
                success: 'Production Entry Submitted Successfully'

            };
        }
        case type.ADD_PRODUCTION_ENTRY_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }


        case type.UPDATE_PRODUCTION_ENTRY_REQUESTED: {
            return {
                ...state,
                loading: true,
            };
        }

        case type.UPDATE_PRODUCTION_ENTRY_SUCCESS: {
            return {
                ...state,
                loading: false,
                production_entries_details: action.data,
                success: 'Production Entry Updated Successfully'

            };
        }


        case type.UPDATE_PRODUCTION_ENTRY_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }



        case type.SET_PRODUCTION_ENTRY_SUCCESS_ALERT: {

            return {
                ...state,
                success: action.payload,
            };
        }

        case type.SET_PRODUCTION_ENTRY_ERROR_ALERT: {

            return {
                ...state,
                error: action.payload,
            };
        }

        default:
            return state;
    }
};

export default ProductionEntry;
