import { all } from 'redux-saga/effects';

import authSaga from './auth/saga';
import operatorsSaga from './operators/saga';
import stylesSaga from './styles/saga';
import productionEntrySaga from './production_entry/saga';
import layoutSaga from './layout/saga';

export default function* rootSaga() {
    yield all([authSaga(), operatorsSaga(), stylesSaga(), productionEntrySaga(), layoutSaga()]);
}
