import * as type from './types';


interface StyleForm {
    style_no: string;
    buyer: string;
    linking_rate: number;
    trimming_rate: number;
    mending_rate: number;
    type: string;
}

export const getStyle = (limit: number, page: number) => ({
    type: type.GET_STYLE_REQUESTED,
    payload: { limit, page },
});


export const getAllStyle = (style_type?: any) => {
    if (style_type !== undefined && style_type !== null) {
        return {
            type: type.GET_ALLSTYLE_REQUESTED,
            payload: { type: style_type },
        };
    } else {
        return {
            type: type.GET_ALLSTYLE_REQUESTED,
            payload: {},
        };
    }
};


export const getAllActiveOrInactiveStyles = (is_active: string, style_type?: any) => {

    if (style_type !== undefined && style_type !== null) {
        return {
            type: type.GET_ACTIVE_OR_INACTIVE_STYLE_REQUESTED,
            payload: { is_active: is_active, type: style_type },
        }
    }
    else {
        return {
            type: type.GET_ACTIVE_OR_INACTIVE_STYLE_REQUESTED,
            payload: { is_active },
        }
    }
}


export const addStyle = (formData: StyleForm) => ({
    type: type.ADD_STYLE_REQUESTED,
    payload: formData,
});

export const updateStyle = (formData: any) => ({
    type: type.UPDATE_STYLE_REQUESTED,
    payload: formData,
});


export const setStyleSuccessAlert = (msg: string) => ({
    type: type.SET_STYLE_SUCCESS_ALERT,
    payload: msg,
});

export const setStyleErrorAlert = (msg: string) => ({
    type: type.SET_STYLE_ERROR_ALERT,
    payload: msg,
});