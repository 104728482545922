import * as type from './types';


interface ProductionEntryForm {
    operator: string;
    style: string;
    quantity: string;
    entry_date: string;
    type: string;
}

export const getProductionEntry = (limit: number, page: number) => ({
    type: type.GET_PRODUCTION_ENTRY_REQUESTED,
    payload: { limit, page },
});


export const getAllProductionEntry = (entry_type?: any, entry_date?: any) => {
    return ({
        type: type.GET_ALL_PRODUCTION_ENTRY_REQUESTED,
        payload: { type: entry_type, date: entry_date },
    })
};

export const addProductionEntry = (formData: ProductionEntryForm) => ({
    type: type.ADD_PRODUCTION_ENTRY_REQUESTED,
    payload: formData,
});

export const updateProductionEntry = (formData: any) => ({
    type: type.UPDATE_PRODUCTION_ENTRY_REQUESTED,
    payload: formData,
});

export const setProductionEntrySuccessAlert = (msg: string) => ({
    type: type.SET_PRODUCTION_ENTRY_SUCCESS_ALERT,
    payload: msg,
});

export const setProductionEntryErrorAlert = (msg: string) => ({
    type: type.SET_PRODUCTION_ENTRY_ERROR_ALERT,
    payload: msg,
});