import * as type from './types';

const INIT_STATE = {
    operators: [],
    all_operators: [],
    previous: '',
    next: '',
    current_page: '',
    total_object: '',
    total_page: '',
    active: '',
    loading: false,
    error: null,
    success: '',
};



const Operators = (state = INIT_STATE, action: any) => {
    switch (action.type) {

        case type.GET_OPERATOR_REQUESTED: {
            return {
                ...state,
                loading: true,
            };
        }
        case type.GET_OPERATOR_SUCCESS: {
            return {
                ...state,
                loading: false,
                operators: action.data.results,
                previous: action.data.previous,
                next: action.data.next,
                current_page: action.data.current_page,
                total_object: action.data.total_object,
                total_page: action.data.total_page,
                active: action.data.current_page,

            };
        }
        case type.GET_OPERATOR_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }

        case type.GET_ALLOPERATOR_REQUESTED: {
            return {
                ...state,
                loading: true,
            };
        }
        case type.GET_ALLOPERATOR_SUCCESS: {
            return {
                ...state,
                loading: false,
                all_operators: action.data,
                previous: action.data.previous,
                next: action.data.next,
                current_page: action.data.current_page,
                total_page: action.data.total_page,
                active: action.data.current_page,
            };
        }
        case type.GET_ALLOPERATOR_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }

        case type.ADD_OPERATOR_REQUESTED: {
            return {
                ...state,
                loading: true,
            };
        }
        case type.ADD_OPERATOR_SUCCESS: {
            return {
                ...state,
                loading: false,
                all_operators: [action.operator, ...state.all_operators],
                success: 'Operator Created Successfully'

            };
        }
        case type.ADD_OPERATOR_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }


        case type.UPDATE_OPERATOR_REQUESTED: {
            return {
                ...state,
                loading: true,
            };
        }

        case type.UPDATE_OPERATOR_SUCCESS: {
            return {
                ...state,
                loading: false,
                operators_details: action.data,
                success: 'Operator Updated Successfully'

            };
        }


        case type.UPDATE_OPERATOR_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }


        case type.GET_ACTIVE_OR_INACTIVE_OPERATOR_REQUESTED: {
            return {
                ...state,
                loading: true,
            };
        }

        case type.GET_ACTIVE_OR_INACTIVE_OPERATOR_SUCCESS: {

            const all_operators_for_select = action?.data?.map((operator: any) => {
                const newOper = { ...operator };
                newOper['value'] = newOper.id;
                newOper['label'] = newOper.name;

                return newOper;
            })
            return {
                ...state,
                loading: false,
                all_operators: action.data,
                all_operators_for_select: all_operators_for_select,

            };
        }
        case type.GET_ACTIVE_OR_INACTIVE_OPERATOR_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }


        case type.SET_OPERATOR_SUCCESS_ALERT: {

            return {
                ...state,
                success: action.payload,
            };
        }

        case type.SET_OPERATOR_ERROR_ALERT: {

            return {
                ...state,
                error: action.payload,
            };
        }

        default:
            return state;
    }
};

export default Operators;
