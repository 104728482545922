export const GET_STYLE_REQUESTED ='GET_STYLE_REQUESTED'
export const GET_STYLE_SUCCESS ='GET_STYLE_SUCCESS'
export const GET_STYLE_FAILED ='GET_STYLE_FAILED'

export const GET_ALLSTYLE_REQUESTED ='GET_ALLSTYLE_REQUESTED'
export const GET_ALLSTYLE_SUCCESS ='GET_ALLSTYLE_SUCCESS'
export const GET_ALLSTYLE_FAILED ='GET_ALLSTYLE_FAILED'

export const ADD_STYLE_REQUESTED ='ADD_STYLE_REQUESTED'
export const ADD_STYLE_SUCCESS ='ADD_STYLE_SUCCESS'
export const ADD_STYLE_FAILED ='ADD_STYLE_FAILED'

export const UPDATE_STYLE_REQUESTED ='UPDATE_STYLE_REQUESTED'
export const UPDATE_STYLE_SUCCESS ='UPDATE_STYLE_SUCCESS'
export const UPDATE_STYLE_FAILED ='UPDATE_STYLE_FAILED'


export const GET_ACTIVE_OR_INACTIVE_STYLE_REQUESTED ='GET_ACTIVE_OR_INACTIVE_STYLE_REQUESTED'
export const GET_ACTIVE_OR_INACTIVE_STYLE_SUCCESS ='GET_ACTIVE_OR_INACTIVE_STYLE_SUCCESS'
export const GET_ACTIVE_OR_INACTIVE_STYLE_FAILED ='GET_ACTIVE_OR_INACTIVE_STYLE_FAILED'


export const SET_STYLE_SUCCESS_ALERT ='SET_STYLE_SUCCESS_ALERT'
export const SET_STYLE_ERROR_ALERT ='SET_STYLE_ERROR_ALERT'

export const STYLE_DETAILS_CLEAR ='STYLE_DETAILS_CLEAR'