import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// helpers
import {
    getProductionEntry as getProductionEntryApi,
    getAllProductionEntry as getAllProductionEntryApi,
    addProductionEntry as addProductionEntryApi,
    updateProductionEntry as updateProductionEntryApi,

} from '../../helpers';

interface ProductionEntryData {
    payload: {
        id: number;
        operator: string;
        style: string;
        type: string;
        quantity: string;
        entry_date: string;
        limit: number;
        page: number;
    };
    type: string;
}

function* getProductionEntry({ payload: { limit, page } }: ProductionEntryData): SagaIterator {
    try {
        const response = yield call(getProductionEntryApi, { limit, page });
        const data = response.data;
        yield put({ type: 'GET_PRODUCTION_ENTRY_SUCCESS', data: data });
    } catch (error) {
        yield put({ type: 'GET_PRODUCTION_ENTRY_FAILED', error: error });

    }
}



function* getAllProductionEntry({ payload: { type, date } }: any): SagaIterator {
    try {
        const response = yield call(getAllProductionEntryApi, { type, date });
        const data = response?.data?.data;
        yield put({ type: 'GET_ALL_PRODUCTION_ENTRY_SUCCESS', data: data });
    } catch (error) {
        yield put({ type: 'GET_ALL_PRODUCTION_ENTRY_FAILED', error: error });

    }
}



function* addProductionEntry({ payload: { operator, style, quantity, type, entry_date } }: ProductionEntryData): SagaIterator {

    try {
        const response = yield call(addProductionEntryApi, { operator, style, quantity, type, entry_date });
        const result = response.data;
        if (result.success) {
            yield put({ type: 'ADD_PRODUCTION_ENTRY_SUCCESS', production_entry: result.data });
        } else {
            yield put({ type: 'ADD_PRODUCTION_ENTRY_FAILED', error: result.error });
        }

    } catch (error) {
        yield put({ type: 'ADD_PRODUCTION_ENTRY_FAILED', error: error });

    }
}


function* updateProductionEntry({ payload: { id, operator, style, quantity, type } }: any): SagaIterator {
    try {
        const response = yield call(updateProductionEntryApi, { id, operator, style, quantity, type, });
        const data = response.data;
        if (data.success) {
            yield put({ type: 'UPDATE_PRODUCTION_ENTRY_SUCCESS', data: data.data });
        } else {
            yield put({ type: 'UPDATE_PRODUCTION_ENTRY_FAILED', error: data.error });
        }
    } catch (error) {
        console.log("error", error)
        yield put({ type: 'UPDATE_PRODUCTION_ENTRY_FAILED', error: error });

    }
}



function* setProductionEntrySuccessAlert(msg: string) {

    put({ type: 'SET_PRODUCTION_ENTRY_SUCCESS_ALERT', success: msg });
}

function* setProductionEntryErrorAlert(msg: string) {

    put({ type: 'SET_PRODUCTION_ENTRY_ERROR_ALERT', error: msg });
}


export function* watchGetProductionEntry() {
    yield takeEvery('GET_PRODUCTION_ENTRY_REQUESTED', getProductionEntry);
}

export function* watchGetAllProductionEntry() {
    yield takeEvery('GET_ALL_PRODUCTION_ENTRY_REQUESTED', getAllProductionEntry);
}

export function* watchAddProductionEntry() {
    yield takeEvery('ADD_PRODUCTION_ENTRY_REQUESTED', addProductionEntry);
}

export function* watchUpdateProductionEntry() {
    yield takeEvery('UPDATE_PRODUCTION_ENTRY_REQUESTED', updateProductionEntry);
}



function* productionEntrySaga() {
    yield all([fork(watchGetProductionEntry), fork(watchAddProductionEntry), fork(watchUpdateProductionEntry), fork(watchGetAllProductionEntry), setProductionEntrySuccessAlert, setProductionEntryErrorAlert]);
}

export default productionEntrySaga;
