import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// helpers
import {
    getOperator as getOperatorApi,
    getAllOperator as getAllOperatorApi,
    getAllActiveOrInactiveOperator as getAllActiveOrInactiveOperatorApi,
    addOperator as addOperatorApi,
    updateOperator as updateOperatorApi,

} from '../../helpers';

interface OperatorData {
    payload: {
        id: number;
        card_no: string;
        name: string;
        phone: string;
        type: string;
        sub_type: string;
        limit: number;
        page: number;
    };
    type: string;
}

function* getOperator({ payload: { limit, page } }: OperatorData): SagaIterator {
    try {
        const response = yield call(getOperatorApi, { limit, page });
        const data = response.data;
        yield put({ type: 'GET_OPERATOR_SUCCESS', data: data });
    } catch (error) {
        yield put({ type: 'GET_OPERATOR_FAILED', error: error });

    }
}



function* getAllOperator({ payload: { type } }: any): SagaIterator {
    try {
        const response = yield call(getAllOperatorApi, { type });
        const data = response?.data?.data;
        yield put({ type: 'GET_ALLOPERATOR_SUCCESS', data: data });
    } catch (error) {
        yield put({ type: 'GET_ALLOPERATOR_FAILED', error: error });

    }
}

function* getAllActiveOrInactiveOperator({ payload: { is_active, type } }: any): SagaIterator {
    try {
        const response = yield call(getAllActiveOrInactiveOperatorApi, { is_active, type });
        const data = response?.data?.data;
        yield put({ type: 'GET_ACTIVE_OR_INACTIVE_OPERATOR_SUCCESS', data: data });
    } catch (error) {
        yield put({ type: 'GET_ACTIVE_OR_INACTIVE_OPERATOR_FAILED', error: error });

    }
}



function* addOperator({ payload: { name, card_no, phone, type, sub_type } }: OperatorData): SagaIterator {

    try {
        const response = yield call(addOperatorApi, { name, card_no, phone, type, sub_type });
        const result = response.data;
        if (result.success) {
            yield put({ type: 'ADD_OPERATOR_SUCCESS', operator: result.data });
        } else {
            yield put({ type: 'ADD_OPERATOR_FAILED', error: result.error });
        }

    } catch (error) {
        yield put({ type: 'ADD_OPERATOR_FAILED', error: error });

    }
}


function* updateOperator({ payload: { id, name, card_no, phone, type } }: any): SagaIterator {
    try {
        const response = yield call(updateOperatorApi, { id, name, card_no, phone, type, });
        const data = response.data;
        if (data.success) {
            yield put({ type: 'UPDATE_OPERATOR_SUCCESS', data: data.data });
        } else {
            yield put({ type: 'UPDATE_OPERATOR_FAILED', error: data.error });
        }
    } catch (error) {
        console.log("error", error)
        yield put({ type: 'UPDATE_OPERATOR_FAILED', error: error });

    }
}



function* setOperatorSuccessAlert(msg: string) {

    put({ type: 'SET_OPERATOR_SUCCESS_ALERT', success: msg });
}

function* setOperatorErrorAlert(msg: string) {

    put({ type: 'SET_OPERATOR_ERROR_ALERT', error: msg });
}


export function* watchGetOperator() {
    yield takeEvery('GET_OPERATOR_REQUESTED', getOperator);
}

export function* watchGetAllOperator() {
    yield takeEvery('GET_ALLOPERATOR_REQUESTED', getAllOperator);
}
export function* watchGetAllActiveOrInactiveOperator() {
    yield takeEvery('GET_ACTIVE_OR_INACTIVE_OPERATOR_REQUESTED', getAllActiveOrInactiveOperator);
}

export function* watchAddOperator() {
    yield takeEvery('ADD_OPERATOR_REQUESTED', addOperator);
}

export function* watchUpdateOperator() {
    yield takeEvery('UPDATE_OPERATOR_REQUESTED', updateOperator);
}



function* operatorSaga() {
    yield all([fork(watchGetOperator), fork(watchAddOperator), fork(watchUpdateOperator), fork(watchGetAllOperator), fork(watchGetAllActiveOrInactiveOperator), setOperatorSuccessAlert, setOperatorErrorAlert]);
}

export default operatorSaga;
