import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// helpers
import {
    getStyle as getStyleApi,
    getAllStyle as getAllStyleApi,
    getAllActiveOrInactiveStyle as getAllActiveOrInactiveStyleApi,
    addStyle as addStyleApi,
    updateStyle as updateStyleApi,

} from '../../helpers';

interface StyleData {
    payload: {
        id: number;
        style_no: string;
        buyer: string;
        linking_rate: number;
        trimming_rate: number;
        mending_rate: number;
        type: string;
        limit: number;
        page: number;
    };
    type: string;
}

function* getStyle({ payload: { limit, page } }: StyleData): SagaIterator {
    try {
        const response = yield call(getStyleApi, { limit, page });
        const data = response.data;
        yield put({ type: 'GET_STYLE_SUCCESS', data: data });
    } catch (error) {
        yield put({ type: 'GET_STYLE_FAILED', error: error });

    }
}



function* getAllStyle({ payload: { type } }: any): SagaIterator {
    try {
        const response = yield call(getAllStyleApi, { type });
        const data = response?.data?.data;
        yield put({ type: 'GET_ALLSTYLE_SUCCESS', data: data });
    } catch (error) {
        yield put({ type: 'GET_ALLSTYLE_FAILED', error: error });

    }
}

function* getAllActiveOrInactiveStyle({ payload: { is_active, type } }: any): SagaIterator {
    try {
        const response = yield call(getAllActiveOrInactiveStyleApi, { is_active, type });
        const data = response?.data?.data;
        yield put({ type: 'GET_ACTIVE_OR_INACTIVE_STYLE_SUCCESS', data: data });
    } catch (error) {
        yield put({ type: 'GET_ACTIVE_OR_INACTIVE_STYLE_FAILED', error: error });

    }
}



function* addStyle({ payload: { style_no, buyer, linking_rate, trimming_rate, mending_rate, type } }: StyleData): SagaIterator {

    try {
        const response = yield call(addStyleApi, { style_no, buyer, linking_rate, trimming_rate, mending_rate, type });
        const result = response.data;
        if (result.success) {
            yield put({ type: 'ADD_STYLE_SUCCESS', style: result.data });
        } else {
            yield put({ type: 'ADD_STYLE_FAILED', error: result.error });
        }

    } catch (error) {
        yield put({ type: 'ADD_STYLE_FAILED', error: error });

    }
}


function* updateStyle({ payload: { id, style_no, buyer, rate, type } }: any): SagaIterator {
    try {
        const response = yield call(updateStyleApi, { id, style_no, buyer, rate, type });
        const data = response.data;
        if (data.success) {
            yield put({ type: 'UPDATE_STYLE_SUCCESS', data: data.data });
        } else {
            yield put({ type: 'UPDATE_STYLE_FAILED', error: data.error });
        }
    } catch (error) {
        console.log("error", error)
        yield put({ type: 'UPDATE_STYLE_FAILED', error: error });

    }
}


function* setStyleSuccessAlert(msg: string) {

    put({ type: 'SET_STYLE_SUCCESS_ALERT', success: msg });
}

function* setStyleErrorAlert(msg: string) {

    put({ type: 'SET_STYLE_ERROR_ALERT', error: msg });
}


export function* watchGetStyle() {
    yield takeEvery('GET_STYLE_REQUESTED', getStyle);
}

export function* watchGetAllStyle() {
    yield takeEvery('GET_ALLSTYLE_REQUESTED', getAllStyle);
}

export function* watchGetAllActiveOrInactiveStyle() {
    yield takeEvery('GET_ACTIVE_OR_INACTIVE_STYLE_REQUESTED', getAllActiveOrInactiveStyle);
}

export function* watchAddStyle() {
    yield takeEvery('ADD_STYLE_REQUESTED', addStyle);
}

export function* watchUpdateStyle() {
    yield takeEvery('UPDATE_STYLE_REQUESTED', updateStyle);
}



function* StyleSaga() {
    yield all([fork(watchGetStyle), fork(watchAddStyle), fork(watchUpdateStyle), fork(watchGetAllStyle), fork(watchGetAllActiveOrInactiveStyle), setStyleSuccessAlert, setStyleErrorAlert]);
}

export default StyleSaga;
