import { APICore } from './apiCore';

const api = new APICore();


function getOperator(params: { limit: number, page: number }) {
    const baseUrl = '/api/operators/';
    return api.get(`${baseUrl}`, params);
}

function getAllOperator(params: { type: any }) {
    const baseUrl = '/api/operators/';
    const new_params = params?.type !== undefined ? { 'type': params?.type } : {}
    return api.get(`${baseUrl}`, new_params);
}

function getAllActiveOrInactiveOperator(params: { is_active: string, type: string }) {
    const baseUrl = '/api/operators/';
    const new_type = (params?.type !== undefined && params?.type !== null) ? (params?.type === "LINKING_7GG" || params?.type === "LINKING_12GG") ? "LINKING" : params?.type : null
    const sub_type = new_type === "LINKING" ? params?.type?.split('_')[1] : null

    const new_params = {
        ...(params.is_active !== undefined ? { 'is_active': params.is_active } : {}),
        'type': new_type,
        'sub_type': sub_type
    };

    const filtered_params = Object.fromEntries(
        Object.entries(new_params).filter(([key, value]) => value !== null)
    );

    return api.get(`${baseUrl}`, filtered_params);
}

function addOperator(params: { name: string, card_no: string, phone: string, type: string, sub_type: string }) {
    const baseUrl = '/api/operators/';
    return api.create(`${baseUrl}`, params);
}

function updateOperator(params: { id: any, name: string, card_no: string, phone: string, type: string }) {
    const baseUrl = `/api/operators/${params?.id}/`;
    return api.updatePatch(`${baseUrl}`, params);
}


export { getOperator, addOperator, getAllOperator, getAllActiveOrInactiveOperator, updateOperator };
