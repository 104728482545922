import { APICore } from './apiCore';

const api = new APICore();


function getProductionEntry(params: { limit: number, page: number }) {
    const baseUrl = '/api/production-entries/';
    return api.get(`${baseUrl}`, params);
}

function getAllProductionEntry(params: { [key: string]: any }) {
    const newParams: { [key: string]: any } = {};

    for (const key in params) {
        if (params[key] !== null) {
            newParams[key] = params[key];
        }
    }

    const baseUrl = '/api/production-entries/';
    return api.get(`${baseUrl}`, newParams);
}

function addProductionEntry(params: { operator: string, style: string, quantity: string, type: string, entry_date: string }) {
    const baseUrl = '/api/production-entries/';
    return api.create(`${baseUrl}`, params);
}

function updateProductionEntry(params: { id: any, operator: string, style: string, quantity: string, type: string }) {
    const baseUrl = `/api/production-entries/${params?.id}/`;
    return api.updatePatch(`${baseUrl}`, params);
}


export { getProductionEntry, addProductionEntry, getAllProductionEntry, updateProductionEntry };
