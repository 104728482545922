import * as type from './types';


interface OperatorForm {
    name: string;
    card_no: string;
    phone: string;
    type: string;
    sub_type: string;
}

export const getOperator = (limit: number, page: number) => ({
    type: type.GET_OPERATOR_REQUESTED,
    payload: { limit, page },
});


export const getAllOperator = (operator_type?: any) => {
    if (operator_type !== undefined && operator_type !== null) {
        return {
            type: type.GET_ALLOPERATOR_REQUESTED,
            payload: { type: operator_type },
        };
    } else {
        return {
            type: type.GET_ALLOPERATOR_REQUESTED,
            payload: {},
        };
    }
};

export const addOperator = (formData: OperatorForm) => ({
    type: type.ADD_OPERATOR_REQUESTED,
    payload: formData,
});

export const updateOperator = (formData: any) => ({
    type: type.UPDATE_OPERATOR_REQUESTED,
    payload: formData,
});


export const getAllActiveOrInactiveOperators = (is_active: string, operator_type: string) => ({
    type: type.GET_ACTIVE_OR_INACTIVE_OPERATOR_REQUESTED,
    payload: { is_active, "type": operator_type },
});

export const setOperatorSuccessAlert = (msg: string) => ({
    type: type.SET_OPERATOR_SUCCESS_ALERT,
    payload: msg,
});

export const setOperatorErrorAlert = (msg: string) => ({
    type: type.SET_OPERATOR_ERROR_ALERT,
    payload: msg,
});