import { combineReducers } from 'redux';

import Auth from './auth/reducers';
import Operators from './operators/reducers';
import Styles from './styles/reducers';
import ProductionEntry from './production_entry/reducers';
import Layout from './layout/reducers';

export default combineReducers({
    Auth,
    Operators,
    Styles,
    ProductionEntry,
    Layout,
});
